@import '../../styles/theme.sass'

.footer
  width: 100%

  .bannerBottom
    width: 100%
    height: auto
    display: block

  .footerBackground
    background-color: $dark-green

    .footerContent
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      text-align: center
      padding: 0 10px 10px 10px

      +mediawidth(md)
        flex-direction: row
        align-items: flex-start
        justify-content: space-between
        text-align: left

      .social,
      .textContent
        padding: 10px

      .textContent
        p
          color: $brand-green

      .social
        padding: 10px