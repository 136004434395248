@import './breakpoints.sass'
@import './colors.sass'

.noMob
  display: none

  +mediawidth(md)
    display: auto

.onlyMob
  display: auto

  +mediawidth(md)
    display: none

.container,
.moduleContainer
  width: 100%
  max-width: 800px
  margin: 0 auto
  text-align: center
  margin: 0 auto

  +mediawidth(md)
    height: auto

.container
  padding: 20px
  flex: 1 1 auto

.moduleContainer
  padding: 30px 20px
  min-height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  +mediawidth(md)
    padding: 60px 20px
    height: auto
    min-height: 60vh

.textField
  border: 2px solid black
  padding: 10px
  border-radius: 4px
  outline: 0
  width: 100%

  &.danger
    border-color: $danger

.dangerText
  color: $danger

.textfield
  border: 2px solid black
  border-radius: 4px
  padding: 10px 20px
  outline: 0
  width: 100%

.invalid
  border: 2px solid $danger

.warningText
  color: $danger

.button
  display: inline-block
  border-radius: 4px
  border: none
  outline: none
  padding: 10px 20px 10px 20px
  font-size: 18px
  line-height: 22px
  text-decoration: none
  font-family: 'Righteous', sans-serif
  cursor: pointer

  &.primary
    background-color: $brand-green
    color: #000000

    &:hover
      background-color: #ffffff
      color: #000000

  &.outline
    background-color: transparent
    color: black
    border: 2px solid black

    &:hover
      background-color: transparent
      color: black
      border: 2px solid black

  &.outlineReverse
    background-color: transparent
    color: #ffffff
    border: 2px solid #ffffff

    &:hover
      background-color: transparent
      color: black
      border: 2px solid black

  &.tertiary
    background-color: #000000
    color: #ffffff

    &:hover
      background-color: black
      color: #ffffff

  &.disabled
    background-color: black
    color: #ffffff

    &:hover
      background-color: black
      color: #ffffff

.navButtons
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  margin: 20px 0 0 0
  padding: 20px 0 0 0
  border-top: 2px solid black

  div,
  button
    width: 48%

h1, h2, h3, h4, h5, h6
  font-family: 'Righteous', sans-serif
  color: $brand-green
  font-weight: 100
  margin: 0 0 10px 0

h1
  font-size: 50px
  line-height: 54px

  +mediawidth(md)
    font-size: 100px
    line-height: 104px

h2
  font-size: 26px
  line-height: 30px

  +mediawidth(md)
    font-size: 50px
    line-height: 54px

h3
  font-size: 16px
  line-height: 20px

  +mediawidth(md)
    font-size: 20px
    line-height: 20px

p
  margin: 0 0 10px 0
  font-size: 16px
  line-height: 26px

.video
  overflow: hidden
  padding-bottom: 56.25%
  position: relative
  height: 0
  margin: 0

  iframe
    left: 0
    top: 0
    height: 100%
    width: 100%
    position: absolute

.transparent
  opacity: 0

.fadeFromLeft
  -moz-animation: fadeFromLeft 1s
  -webkit-animation: fadeFromLeft 1s
  animation: fadeFromLeft 1s

.fadeFromRight
  -moz-animation: fadeFromRight 1s
  -webkit-animation: fadeFromRight 1s
  animation: fadeFromRight 1s

.fadeFromBelow
  -moz-animation: fadeFromBelow 1s
  -webkit-animation: fadeFromBelow 1s
  animation: fadeFromBelow 1s

ul, ol
  padding: 0 0 30px 30px

.columns
  display: flex
  flex-direction: column
  gap: 0

  +mediawidth(md)
    flex-direction: row
    gap: 30px

.column
  text-align: left
  flex: 1


.mt30
  margin-top: 30px

.mb30
  margin-bottom: 30px



@keyframes bounce
  0%
    transform: translateY(0)

  50%
    transform: translateY(-20px)

  100%
    transform: translateY(0)

@keyframes fadeFromLeft
  0%
    opacity: 0

  100%
    opacity: 1

@keyframes fadeFromRight
  0%
    opacity: 0

  100%
    opacity: 1

@keyframes fadeFromBelow
  0%
    opacity: 0
    transform: translateY(100px)

  100%
    opacity: 1
    transform: translateY(0)
