@import '../../styles/theme.sass'

.Header
  width: 100%

  a
    text-decoration: none

  .bannerBottom
    width: 100%
    height: auto
    display: block

  .HeaderBackground
    background-color: $dark-green

    .HeaderContent
      display: flex
      flex-direction: row
      align-items: center
      justify-content: flex-start
      text-align: left
      gap: 10px

      +mediawidth(md)
        gap: 20px

      img
        width: 30px

        +mediawidth(md)
          width: 50px

      .textContent
        padding: 10px 0 0 0

        p
          color: $brand-green
