@import '../../styles/theme.sass'


.projects
  display: flex
  flex-direction: rows
  flex-wrap: wrap
  justify-content: flex-start
  gap: 15px
  padding: 30px 0

  +mediawidth(md)
    flex-direction: rows
    gap: 20px

  .project
    padding: 15px
    background-color: $dark-green
    border-radius: 10px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    width: 100%

    +mediawidth(md)
      flex-direction: rows
      max-width: 47%

    .projectDetails
      display: flex
      flex-direction: column
      gap: 20px
      flex: 1


    .textContainer
      width: 100%

    .textContainer
    .button
      margin: 10px 0 0 0

    .video, img
      border-radius: 5px

    .thumbnailContainer, img
      width: 100%



.downArrow
  width: 30px
  margin: 30px 0 0 0
  -moz-animation: bounce 2s infinite
  -webkit-animation: bounce 2s infinite
  animation: bounce 2s infinite