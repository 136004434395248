@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Righteous&display=swap')

*
  box-sizing: border-box
  padding: 0
  margin: 0
  color: #ffffff
  font-family: 'Open Sans', sans-serif

html, body, #root
  height: 100%
  background-color: #000000

