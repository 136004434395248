@import '../../styles/theme.sass'

.headerImage
  width: 100%
  min-height: 220px
  display: flex
  align-items: center
  justify-content: center
  padding: 30px
  background-size: cover
  background-position: center center
  background-repeat: no-repeat
  border-radius: 10px

  +mediawidth(md)
    min-height: 350px

.projectDetails
  text-align: left
  padding: 30px
  min-height: 70vh

h1
  font-size:50px
  padding: 0 0 20px 0

  +mediawidth(md)
    font-size:70px

.summaryText
  text-align: left

  ul
    padding: 0 0 0 30px