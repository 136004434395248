@import '../../styles/theme.sass'

.header
  width: 100%
  padding: 100px 0 0 0
  background-image: url(../../../public/images/banner-top.gif)
  background-position: top center
  background-size: 2400px
  background-repeat: repeat-x

  +mediawidth(md)
    padding: 125px 0 0 0

  div
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center

    .herologo
      width: 80px
      margin: 0 0 30px 0

      +mediawidth(md)
        width: 130px
        margin: 10px 0 40px 0

    h1
      margin: 0 0 20px 0

      +mediawidth(md)
        margin: 0 0 40px 0
    p
      margin: 0 0 10px 0
      font-size: 16px
      line-height: 26px

      +mediawidth(md)
        font-size: 20px
        line-height: 30px

    .findOutMore
      margin: 20px 0 0 0
      cursor: pointer

      +mediawidth(md)
        margin: 40px 0 0 0

    .downArrow
      width: 60px
      margin: 30px 0 0 0
      -moz-animation: bounce 2s infinite
      -webkit-animation: bounce 2s infinite
      animation: bounce 2s infinite

      +mediawidth(md)
        width: 100px
        margin: 40px 0 0 0

