@import '../../styles/theme.sass'


.summaryText
  padding: 30px
  text-align: left

h1
  font-size:50px
  padding: 0 0 20px 0

  +mediawidth(md)
    font-size:70px

.downArrow
  width: 30px
  margin: 30px 0 0 0
  -moz-animation: bounce 2s infinite
  -webkit-animation: bounce 2s infinite
  animation: bounce 2s infinite

.jobs
  display: flex
  flex-direction: rows
  flex-wrap: wrap
  justify-content: flex-start
  gap: 15px
  padding: 30px 0

  +mediawidth(md)
    flex-direction: rows
    gap: 20px

  .job
    padding: 15px
    background-color: $dark-green
    border-radius: 10px
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    text-align: left
    width: 100%

    +mediawidth(md)
      flex-direction: rows
      max-width: 300px

    div
      flex: 1

.extendHeight
  min-height: 70vh