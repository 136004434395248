$xs: 0
$sm: 600px
$md: 820px
$lg: 980px

$breakpoint-map: (xs: $xs, sm: $sm, md: $md, lg: $lg)

=mediawidth($breakpoint)
  @if map-has-key($breakpoint-map, $breakpoint)
    @media (min-width: map-get($breakpoint-map, $breakpoint))
      @content
