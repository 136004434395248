@import '../../styles/theme.sass'

.videoContainer
  padding: 10px
  background-color: $dark-green
  border-radius: 10px
  margin: 40px 0 40px 0
  width: 100%

.downArrow
  width: 30px
  margin: 30px 0 0 0
  -moz-animation: bounce 2s infinite
  -webkit-animation: bounce 2s infinite
  animation: bounce 2s infinite